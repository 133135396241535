<template>
  <div class="relative mb-1">
    <div class="row mx-0" ref="theInput">
      <div class="col-12 p-0" v-if="label">
        <div class="form-label" v-html="label" :class="{'required': required}"></div>
      </div>
      <div class="col-12 p-0" @click="toggleOpen">
        <div class="position-absolute top-0 start-0 w-100 h-100"></div>
        <input
          v-if="selectedProject && Object.keys(selectedProject).length > 0 && subDisplayValue"
          class="form-control medium cursor pe-5"
          :class="{small: size === 'small', big: size === 'big', gold: color === 'gold', white: color === 'white', error: hasErrors > 0}"
          type="text"
          autocomplete="off"
          :placeholder="placeholder"
          :disabled=true
          v-model="selectedProject[displayValue][subDisplayValue]"
        />
        <input
          v-else-if="selectedProject && Object.keys(selectedProject).length > 0"
          class="form-control medium cursor pe-5"
          :class="{small: size === 'small', big: size === 'big', gold: color === 'gold', white: color === 'white', error: hasErrors > 0}"
          type="text"
          autocomplete="off"
          :placeholder="placeholder"
          :disabled=true
          v-model="selectedProject[displayValue]"
        />
        <input
          v-else
          class="form-control medium cursor pe-5"
          :class="{small: size === 'small', big: size === 'big', gold: color === 'gold', white: color === 'white', error: hasErrors > 0}"
          type="text"
          autocomplete="off"
          :placeholder="placeholder"
          :disabled=true
        />
        <div class="dropdown_icon cursor" :class="{small: size === 'small', big: size === 'big', gold: color === 'gold', white: color === 'white'}"
>
          <ios-arrow-up-icon v-if="isOpen"/>
          <ios-arrow-down-icon v-else />
        </div>
      </div>
    </div>
    <div class="isError text-center" v-for="(error, index) of errors" :key="index" >
      <span v-if="error.$message">{{ error.$message }}</span>
      <span v-else>{{ error }}</span>
    </div>
    <transition name="slideBottom">
      <div class="select_dropdown cursor" :class="{up: goUp, 'with_label': label}" v-if="isOpen" @click.self="toggleOpen">
        <div class="dropdown_inner font15 shadow" :class="{full: width && width === 'full'}">
          <div class="row mx-0 justify-content-center d-md-none">
            <div class="close cursor" @click="close"><ios-close-icon class="inline-icon"/></div>
          </div>
          <div class="row mx-0 mt-2 justify-content-center scroll_inner">
            <div class="col-10 col-md-12 p-0">
              <div class="row m-0 justify-content-center" v-if="filteredItems && Array.isArray(filteredItems) && filteredItems.length > 0" >
                <div class="col-12 px-0 py-2 item cursor" v-for="option in filteredItems" :key="option" @click="selectProject(option)">
                  <div class="row mx-0 justify-content-center">
                    <div class="col medium pe-0">
                      <span v-if="subDisplayValue">{{option[displayValue][subDisplayValue]}}</span>
                      <span v-else>{{option[displayValue]}}</span>
                    </div>
                    <div class="col-auto ps-0" v-if="option.projectID && childProjects(option.projectID).length > 0">
                      <IconChev />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-0 justify-content-center" v-else-if="filteredItems && !Array.isArray(filteredItems) && Object.keys(filteredItems).length > 0">
                <div class="col-12 py-2 medium" v-for="theOption, key in filteredItems" :key="key">
                  <div class="row mx-0 justify-content-center">
                    <div class="col-12 text-muted small">
                      {{ key }}
                    </div>
                    <div class="col-11 py-2 item cursor medium" v-for="option in theOption" :key="option" @click="selectProject(option)"
                    >
                      <span v-if="subDisplayValue">{{option[displayValue][subDisplayValue]}}</span>
                      <span v-else>{{option[displayValue]}}</span></div>
                  </div>
                </div>
              </div>
              <div class="row m-0" v-else>
                <div class="col-12 py-3 medium">
                  No projects match your search
                </div>
              </div>
            </div>
            <div class="col-10 col-md-12 p-0 order-md-first mb-3 mb-md-0 d-none d-md-flex">
              <Search type="text" color="outline" placeholder="Search" align="left" v-model="searchItem" autocomplete="off" v-if="isSearch" />
            </div>
          </div>
          <div class="row mx-0 my-3 justify-content-center d-md-none" v-if="isSearch">
            <div class="col-10 col-md-12 p-0 order-md-first mb-3 mb-md-0">
              <Search type="text" color="outline" placeholder="Search" align="left" v-model="searchItem" autocomplete="off" />
            </div>
          </div>
          <div class="row mx-0 my-3 justify-content-center" v-if="hasMore">
            <div class="col-10">
              <Button color="green" width="100" :btnText="isMore ? 'Show Less' : 'Show More'"  @buttonClicked="toggleMore" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import vClickOutside from 'click-outside-vue3'
import { mapActions, mapGetters } from 'vuex'
import IconChev from './icons/IconChev.vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('./Button.vue')),
    Search: defineAsyncComponent(() => import('./Search.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    'ios-arrow-down-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-down.vue')),
    'ios-arrow-up-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-up.vue')),
    IconChev
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  name: 'SelectAll',
  props: ['placeholder', 'options', 'displayValue', 'subDisplayValue', 'saveValue', 'modelValue', 'errors', 'hasErrors', 'size', 'color', 'disabled', 'label', 'isFixed', 'isSearch', 'autoSelect', 'goUp', 'hasMore', 'width', 'isGrouped', 'required', 'watchFocus'],
  data () {
    return {
      searchItem: '',
      selectedProject: {},
      isOpen: false,
      hasBeenOpened: 0,
      theWidth: null,
      leftPos: null,
      isMore: false
    }
  },
  emits: ['update:modelValue'],
  async mounted () {
    await this.setSelected()
    if (this.autoSelect) {
      this.selectFirst()
    }
  },
  watch: {
    // isOpen () {
    //   if (this.isOpen) {
    //     this.setWidth()
    //   }
    // },
    options: {
      deep: true,
      async handler () {
        await this.reset()
        await this.setSelected()
        await this.selectFirst()
      }
    },
    modelValue: {
      deep: true,
      async handler () {
        await this.setSelected()
      }
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects',
      'isOpenOverlay',
      'isMobile'
    ]),
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    filteredItems () {
      let theOptions = this.options
      if (this.hasMore) {
        if (this.isMore) {
          theOptions = this.options
        } else {
          theOptions = this.options.slice(0, 4)
        }
      }
      if (this.isGrouped) {
        const obj = {
          Other: []
        }
        this.options.forEach(option => {
          if (option.title) {
            if (Object.keys(obj).includes(option.title)) {
              obj[option.title].push(option)
            } else {
              obj[option.title] = [option]
            }
          } else {
            obj.Other.push(option)
          }
        })
        theOptions = obj
        return theOptions
      } else {
        const search = this.searchItem.toLowerCase().trim()
        return theOptions.filter(option => {
          if (search && option[this.displayValue].toLowerCase().indexOf(search) <= -1) {
            return false
          }
          return true
        })
      }
    }
  },
  methods: {
    ...mapActions([
      'setOpen'
    ]),
    notFocus () {
      if (this.watchFocus && this.hasBeenOpened > 1) {
        this.$emit('checkFocus')
      }
    },
    childProjects (parentId) {
      if (parentId) {
        const arr = this.adminProjects.filter(project => {
          if (project.parentProjectID !== parentId) {
            return false
          }
          return true
        })
        return arr
      }
    },
    toggleOpen () {
      if (!this.disabled) {
        this.hasBeenOpened++
        this.notFocus()
        this.isOpen = !this.isOpen
        this.setOpen(this.isOpen)
      }
    },
    reset () {
      this.value = null
    },
    setSelected () {
      if (this.saveValue) {
        if (this.options && this.options.length > 0) {
          var result = this.options.find(obj => {
            return obj[this.saveValue] === this.modelValue
          })
          this.selectedProject = result
        }
      } else {
        this.selectedProject = this.modelValue
      }
    },
    async selectProject (val) {
      if (this.isSearch) {
        if (this.subDisplayValue) {
          this.searchItem = val[this.displayValue][this.subDisplayValue]
        } else {
          this.searchItem = val[this.displayValue]
        }
      }
      this.selectedProject = val
      if (this.saveValue) {
        this.$emit('update:modelValue', val[this.saveValue])
      } else {
        this.$emit('update:modelValue', val)
      }
      this.isOpen = false
      setTimeout(() => {
        this.setOpen(false)
      }, 200)
    },
    selectFirst () {
      if (this.autoSelect) {
        if (this.options && (!this.modelValue || Object.keys(this.modelValue).length < 1)) {
          this.value = this.options[0]
          this.selectedProject = this.options[0]
          this.isOpen = false
          setTimeout(() => {
            this.setOpen(false)
          }, 200)
          if (this.saveValue) {
            this.$emit('update:modelValue', this.options[0][this.saveValue])
          } else {
            this.$emit('update:modelValue', this.options[0])
          }
        }
      }
    },
    close () {
      this.isOpen = false
      setTimeout(() => {
        this.setOpen(false)
      }, 200)
    },
    toggleMore () {
      if (this.isMore) {
        this.isMore = false
      } else {
        this.isMore = true
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    font-weight: 500;
    font-family: "quicksand_medium";
    text-align: left;
  }

  .form-control:disabled {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    font-weight: 500;
    font-family: "quicksand_medium";
    text-align: left;
  }
  .form-control:focus {
    color: var(--gold-color);
    outline: 0;
    box-shadow: none;
  }
  .dropdown_icon {
    position: absolute;
    color: var(--gold-color);
    bottom: 0.5rem;
    right: 1rem;
    z-index: 1;
    width: auto;
    padding-right: 1px;
  }
  .form-control.gold {
    color: var(--gold-color);
  }
  .form-control.white {
    color: #fff;
  }
  .form-control.gold:focus {
    color: var(--gold-color);
  }
  .form-control.white:focus {
    color: #fff;
  }
  .big {
    height: 4.5rem;
    border-radius: 4rem;
    font-size: 2.5rem;
    text-align: center;
    color: var(--gold-color);
  }
  .form-control.big:focus {
    color: var(--gold-color);
  }
  .uppercase {
    text-transform: capitalize;
  }

  .item {
    border-bottom: none;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gold-color) !important;
    opacity: 0.7; /* Firefox */
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--gold-color) !important;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--gold-color) !important;
  }
  .white::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff !important;
    opacity: 0.7; /* Firefox */
  }
  .white:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff !important;
  }
  .white::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff !important;
  }
  .select_dropdown {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 60px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9;
    /* background-color: pink; */
  }
  .close {
    position: absolute;
    right: 0;
    top: 0.5rem;
    width: inherit;
  }
  .dropdown_inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 75vh;
    background-color: rgb(249, 250, 251);
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: rgb(51, 51, 51);
    padding-bottom: 3.5rem;
  }
  .scroll_inner {
    max-height: 68vh;
    overflow-y: auto;
  }
  .form-control {
    background-color: #fff;
    color: var(--green-color);
    border-color: var(--green-color);
    border-radius: 2rem;
    font-size: 1rem;
    height: 2.5rem;
    font-weight: 500;
    text-align: left;
  }
  .form-control.small {
    border: 1px solid var(--green-color-dark);
    font-size: 0.8rem;
    height: calc(2rem + 2px);
    border-radius: 2rem;
  }
  .form-control-inner.small {
    padding: 0 0.5rem;
    height: 2rem;
  }
  .dropdown_icon.small {
    bottom: 0.25rem;
    right: 0.8rem;
  }
  .slideBottom-enter-active {
    transition: all 0.5s ease;
  }
  .slideBottom-leave-active {
    transition: all 0.5s ease;
  }
  .slideBottom-enter-from {
    transform: translateY(100%);
    opacity: 1;
  }
  .slideBottom-leave-to {
    transform: translateY(100%);
    opacity: 1;
  }
@media (min-width: 992px) {
    .select_dropdown {
      position: absolute;
      left: 0;
      bottom: inherit;
      top: 0;
      width: 100%;
      min-height: 60px;
      height: inherit;
      background-color: transparent;
    }
    .dropdown_inner {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: inherit;
      max-height: inherit;
      top: 50px;
      width: 150%;
      min-width: 250px;
      background-color: rgb(249, 250, 251);
      border-radius: 20px;
      color: rgb(51, 51, 51);
      color: var(--green-color);
      padding-bottom: 0;
    }
    .scroll_inner {
      max-height: 300px;
    }
    .with_label .dropdown_inner {
      top: 80px;
    }
    .dropdown_inner.full {
      width: 101%;
    }
    .up .dropdown_inner {
      bottom: 65px;
      top: auto;
    }

    .slideBottom-enter-active {
      transition: all 0.5s ease;
    }
    .slideBottom-leave-active {
      transition: all 0.1s ease;
    }
    .slideBottom-enter-from {
      transform: translateY(0);
      opacity: 0;
    }
    .slideBottom-leave-to {
      transform: translateY(0);
      opacity: 1;
    }
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    font-weight: 500;
    font-family: "quicksand_medium";
    text-align: left;
  }
  .form-control:disabled {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    font-weight: 500;
    font-family: "quicksand_medium";
    text-align: left;
  }
  .form-control:focus {
    color: var(--gold-color);
    outline: 0;
    box-shadow: none;
  }
  .form-control.gold {
    color: var(--gold-color);
  }
  .form-control.white {
    color: #fff;
  }
  .form-control.gold:focus {
    color: var(--gold-color);
  }
  .form-control.white:focus {
    color: #fff;
  }
  .big {
    height: 4.5rem;
    border-radius: 4rem;
    font-size: 2.5rem;
    text-align: center;
    color: var(--gold-color);
  }
  .form-control.big:focus {
    color: var(--gold-color);
  }
  .uppercase {
    text-transform: capitalize;
  }
  .item {
    border-bottom: 1px solid var(--green-color);
  }
  .item:hover {
    background-color: #F4F4F4;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gold-color) !important;
    opacity: 0.7; /* Firefox */
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--gold-color) !important;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--gold-color) !important;
  }
  .white::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff !important;
    opacity: 0.7; /* Firefox */
  }
  .white:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff !important;
  }
  .white::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff !important;
  }
}
</style>
